import { GImage } from '../g-image';
import { currencySymbols } from '@/Data/currency';
import styles from './superlite-product-card.module.css';
import { useCurrency } from '@/Lib/hooks/useCurrency';

type Props = {
  phonesClassName?: string;
  largeHeading?: boolean;
  largeTitleGap?: boolean;
};

const SuperliteProductCard = ({
  phonesClassName = '',
  largeHeading = false,
  largeTitleGap = false,
}: Props) => {
  const { currency } = useCurrency();

  return (
    <a
      className="w-full lg:w-auto bg-superliteBlue rounded-md overflow-hidden border border-light-700 relative cursor-pointer"
      style={{
        height: '100.5%',
        boxShadow: 'rgba(0, 187, 228, 0.15) 2px 2px 6px 0px',
      }}
      href="https://superlite.travel/?utm_source=gwat&utm_medium=homepage&utm_campaign=superlite"
      target="_blank"
    >
      <div className="text-white font-semibold pt-8 md:pt-4 h-full flex flex-col items-center justify-between content-between">
        <div className="px-4 md:px-4">
          <div className={`text-center`}>
            <h6 className="text-xl md:text-lg pb-2">Introducing...</h6>
            <h4 className="text-white text-2xl md:text-xl">
              The SuperLite App
            </h4>
          </div>
          <div className="pt-6">
            <div className="text-white text-sm md:text-2xs font-normal">
              The new DIY travel app for working holidays.
            </div>
            <div className={`text-white text-4xl md:text-2xl font-bold my-3`}>
              <div>Work Overseas</div>
              <div className="mt-1">
                The <span className={styles.stroke}>Easy</span> Easiest Way
              </div>
            </div>
            <div className="text-white text-2xs md:text-2xs font-normal">
              Make Friends. Get Visa. Find Jobs.{' '}
              <span className="md:hidden">Save Money.</span> Stress Less.
            </div>
            <div className="text-white text-sm md:text-xs font-thin pt-2">
              Get instant access{' '}
              <span
                className="font-normal underline decoration-0.5"
                style={{
                  textDecorationThickness: '0.25px',
                }}
              >
                for just {currencySymbols[currency]}99
              </span>{' '}
              <span className="font-normal">
                - limited time<span className="md:hidden"> only</span>.
              </span>
            </div>
          </div>
        </div>

        <div className="flex justify-center items-center mt-6 mb-6">
          <div className="bg-superliteRed text-light-600 bottom-8 text-xl px-4 py-2 rounded-md leading-6 font-semibold">
            Join the Community
          </div>
        </div>
        <div className="justify-center items-center flex">
          <GImage
            path="website/static/superlite-card/mobile-desktop.webp"
            alt="protected umbrella icon"
            visibleByDefault
            classes={`!w-11 h-full object-cover justify-center items-center ${phonesClassName}`}
            wrapperClass="justify-center items-center"
          />
        </div>
      </div>
    </a>
  );
};

export { SuperliteProductCard };
